<script lang="ts" setup>
import { useContextSidebarStore } from '@/store/ContextSidebarStore';
import type { FestivalResource } from '@/types/festival';
import type { GroupResource } from '@/types/group';
import { useGroupRequestsCount } from '@/composables/use-group-requests-count';

type Props = {
  group?: GroupResource;
  festival?: FestivalResource;
};

defineProps<Props>();

const contextSidebarStore = useContextSidebarStore();
const { groupRequestCounts, fetch } = useGroupRequestsCount(contextSidebarStore?.currentGroup?.id);
fetch();
</script>

<template>
  <div
    v-if="groupRequestCounts && groupRequestCounts.pendingCount > 0"
    :title="'There are ' + groupRequestCounts.pendingCount + ' pending requests.'"
    class="mr-[5px] h-[15px] w-[15px] rounded-full bg-pending p-[2px] text-center font-headers text-xxs font-bold text-[hsl(var(--blue-1000))]">
    {{ groupRequestCounts.pendingCount }}
  </div>
</template>
