import { computed, ComputedRef, Ref } from 'vue';
import { storeToRefs } from 'pinia';
import { GroupRequestCountResource, useGroupRequestCountsStore } from '@/store/GroupRequestCountsStore';

export type GroupRequestsCountComposable = {
  groupRequestCounts: ComputedRef<GroupRequestCountResource[]>;
  fetch: (force?: boolean) => Promise<void>;
  addOrUpdateRequestsCount: (groupRequestCount: GroupRequestCountResource) => void;
  readonly loading: Ref<boolean>;
};

export const useGroupRequestsCount = (groupId: number | null): GroupRequestsCountComposable => {
  const { list, loading } = storeToRefs(useGroupRequestCountsStore());
  const { fetchRequestCounts, addOrUpdateRequestsCount: addOrUpdate } = useGroupRequestCountsStore();

  const groupRequestCounts = computed(() => {
    if (groupId == null) return [];
    return list.value.get(groupId) || [];
  });

  const fetch = async (force = false) => {
    if (!groupId) return;
    if (force || !list.value.has(groupId)) {
      await fetchRequestCounts(groupId);
    }
  };

  const addOrUpdateRequestsCount = async (groupRequestCount: GroupRequestCountResource) => {
    if (!groupId) return;
    addOrUpdate(groupId, groupRequestCount);
  };

  return {
    groupRequestCounts,
    addOrUpdateRequestsCount,
    loading,
    fetch,
  };
};
