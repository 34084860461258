<script lang="ts" setup>
import { useToast } from 'vue-toastification';
import { getIndexFromArrayBasedOnId, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import { usePage } from '@inertiajs/vue3';
import { formatStampAsHumanReadableDate, formatStampAsTime, getNow } from '@/util/timeFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import VButton from '@/components/Inputs/VButton.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTable from '@/components/Tables/VTable.vue';
import EmailInput from '@/components/Inputs/EmailInput.vue';
import PhoneInput from '@/components/Inputs/PhoneInput.vue';
import { GuestFieldResource, GuestListResource, GuestListSettingResource, GuestResource } from '@/types/festival';
import { concatName } from '@/services/api-partners';
import { getComponent, getProps } from '@/util/get-component';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import TextareaInput from '@/components/Inputs/TextareaInput.vue';

type Props = {
  guestList: GuestListResource;
  guestListSettings?: GuestListSettingResource;
  canEdit: boolean;
  newlyCreatedGuestId: number | null;
};

const props = withDefaults(defineProps<Props>(), {
  guestListSettings: null,
});
const emits = defineEmits<{
  (event: 'addOrUpdateGuest', arg: GuestListResource): void;
  (event: 'removeGuestId', arg: number): void;
  (event: 'fetch'): void;
}>();

const deleteGuest = async (id: number) => {
  await axios.delete(`/api/guests/${id}`);
  emits('removeGuestId', id);
  useToast().success('Guest deleted');
};

const updateGuestValue = async (id: any, key: string, value: any) => {
  const guest = getItemFromArrayBasedOnId(id, props.guestList.guests);
  if (!guest) return;
  guest[key] = value;
  await axios.patch('/api/guests/' + id, {
    [key]: value,
  });
  guest.name = null;
  guest.name = concatName(guest);
  emits('addOrUpdateGuest', guest);
};

const getFieldValueForGuest = (guest: GuestResource, field: GuestFieldResource) => {
  return getItemFromArrayBasedOnId(
    field.id,
    getKey(guest, 'guest_guest_field_pivots', []),
    { value: '' },
    'guest_field_id'
  ).value;
};

const assignValue = async (guest: GuestResource, field: GuestFieldResource, value: any) => {
  await axios.post('/api/guests/' + guest.id + '/guest-fields/' + field.id, {
    value: value,
  });
  const index = getIndexFromArrayBasedOnId(field.id, guest.guest_guest_field_pivots, 'guest_field_id');
  if (index > -1) {
    guest.guest_guest_field_pivots[index].value = value;
  } else {
    guest.guest_guest_field_pivots.push({
      id: null,
      guest_id: guest.id,
      guest_field_id: field.id,
      value: value,
    });
  }
  emits('addOrUpdateGuest', guest);
};

const approveGuest = async (guest) => {
  if (!props.canEdit) return;
  if (guest.approved_at === null) {
    axios.patch(`/api/guests/${guest.id}/approve`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Already approved.');
        return;
      }
      guest.approved_by = usePage().props.auth.user.name;
      guest.approved_by_id = usePage().props.auth.user.id;
      guest.approved_at = getNow();
      useToast().success('Approved');
      emits('addOrUpdateGuest', guest);
    });
  } else {
    const certain = await useCertaintyModal().assertCertain(
      'Un-approve guest',
      'Are you sure you want to un approve this guest?'
    );
    if (!certain) return;
    axios.patch(`/api/guests/${guest.id}/un-approve`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Not approved yet.');
        return;
      }
      guest.approved_by = null;
      guest.approved_by_id = null;
      guest.approved_at = null;
      useToast().success('Unapproved');
      emits('addOrUpdateGuest', guest);
    });
  }
};

const getGuestApprovedTitle = (guest: GuestResource) => {
  return (
    'Approved at ' +
    formatStampAsTime(guest.approved_at) +
    ' on ' +
    formatStampAsHumanReadableDate(guest.approved_at) +
    (guest.approved_by ? ' by ' + guest.approved_by : '') +
    '.'
  );
};

const showApproveColumn = () => {
  const unapprovedGuests = getKey(props.guestList, 'guests', []).filter((g) => g.approved_at === null);
  if (unapprovedGuests.length > 0) return true;
  return props.guestList.auto_approve !== null
    ? !props.guestList.auto_approve
    : !props.guestList.guest_list_setting.auto_approve;
};
</script>

<template>
  <div class="overflow-auto pl-[1px]">
    <VTable
      v-if="guestList && guestList.guests.length"
      snap-rows
      sticky-header
      sticky-first-column
      edge-to-edge>
      <template #head>
        <VTableRow head>
          <VTableCell class="!pl-[7px]"> First Name</VTableCell>
          <VTableCell> Last Name</VTableCell>
          <VTableCell
            v-if="guestList.usingEmail"
            style="width: 200px">
            Email
          </VTableCell>
          <VTableCell
            v-if="guestList.usingPhone"
            style="width: 150px">
            Phone
          </VTableCell>
          <VTableCell
            v-for="field in getKey(guestListSettings, 'guest_fields', [])"
            style="min-width: 150px">
            {{ field.title }}
          </VTableCell>
          <VTableCell
            v-if="showApproveColumn()"
            style="width: 100px">
            Status
          </VTableCell>
          <VTableCell
            v-if="canEdit"
            style="width: 50px" />
        </VTableRow>
      </template>
      <VTableRow
        v-for="guest in guestList.guests"
        main-row>
        <VTableCell
          has-input
          style="min-width: 150px">
          <TextareaInput
            :set-focus="newlyCreatedGuestId === guest.id"
            :model-value="guest.first_name"
            :can-edit="canEdit"
            is-hidden
            square
            :min-height="38"
            placeholder="First Name"
            @blur="[(guest.first_name = $event), updateGuestValue(guest.id, 'first_name', $event)]" />
        </VTableCell>
        <VTableCell
          has-input
          style="min-width: 150px">
          <TextareaInput
            :model-value="guest.last_name"
            placeholder="Last Name"
            is-hidden
            :min-height="38"
            :can-edit="canEdit"
            square
            @blur="[(guest.last_name = $event), updateGuestValue(guest.id, 'last_name', $event)]" />
        </VTableCell>
        <VTableCell
          v-if="guestList.usingEmail"
          has-input
          style="min-width: 150px">
          <EmailInput
            :email="guest.email"
            label=""
            is-hidden
            placeholder="email"
            :can-edit="canEdit"
            square
            @blur="[(guest.email = $event), updateGuestValue(guest.id, 'email', $event)]" />
        </VTableCell>
        <VTableCell
          v-if="guestList.usingPhone"
          has-input
          style="min-width: 150px">
          <PhoneInput
            :phone="guest.phone"
            :country-code="guest.country_code"
            is-hidden
            square
            :can-edit="canEdit"
            :label="null"
            @update:country-code="[(guest.country_code = $event), updateGuestValue(guest.id, 'country_code', $event)]"
            @phone-blur="[(guest.phone = $event), updateGuestValue(guest.id, 'phone', $event)]" />
        </VTableCell>

        <VTableCell
          v-for="field in getKey(guestListSettings, 'guest_fields', [])"
          has-input>
          <component
            :is="getComponent(field.component)"
            v-bind="getProps(field, { isHidden: true, square: true })"
            :model-value="getFieldValueForGuest(guest, field)"
            :options="field.options"
            :placeholder="field.title"
            :can-edit="canEdit"
            :with-buttons="false"
            @blur="assignValue(guest, field, $event)" />
        </VTableCell>

        <VTableCell v-if="showApproveColumn()">
          <DisplayBadge
            v-if="guest.approved_at"
            size="small"
            :title="getGuestApprovedTitle(guest)"
            classes="w-[80px]"
            text="Approved" />

          <VButton
            v-else-if="canEdit"
            :tool-tip-text="'Not Approved.Click to approve'"
            title="Pending"
            hover-title="Approve"
            size="xs"
            class="group min-w-[90px]"
            icon="fa-exclamation-circle fa-regular"
            hover-icon="fa-check fa-regular"
            type="pending"
            @click.stop="approveGuest(guest)" />

          <div
            v-else
            :title="'Not Approved.'"
            class="btn btn-outline-text btn-xs w-[80px]">
            <i class="fa fa-fw fa-exclamation-circle text-pending" />
            Pending
          </div>
        </VTableCell>
        <VTableCell v-if="canEdit">
          <VButton
            size="sm"
            type="warning"
            icon="fa-trash"
            @click="deleteGuest(guest.id)" />
        </VTableCell>
      </VTableRow>
    </VTable>
  </div>
</template>
