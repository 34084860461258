<script lang="ts" setup>
import { getRoomBooking, OtherUse, Restriction } from '@/services/api-room-booking';
import { RoomBookingResource } from '@/types/room-booking';
import { z } from 'zod';
import { computed, nextTick, ref, watch } from 'vue';
import StartEndPicker from '@/components/Inputs/Date/StartEndPicker.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import VButton from '@/components/Inputs/VButton.vue';
import VTable from '@/components/Tables/VTable.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTableCell from '@/components/Tables/VTableCell.vue';
import { getKey } from '@/util/globals';
import { formatStampAsDate, formatStampAsDateTime, timeStampsAreSame } from '@/util/timeFunctions';
import CheckBox from '@/components/Icons/CheckBox.vue';
import IconWithLoading from '@/components/Icons/IconWithLoading.vue';
import { useDebounceFn } from '@vueuse/core';
import SettingCheck from '@/components/Inputs/Components/SettingCheck.vue';
import CrudSlideout from '@/components/Slideout/CrudSlideout.vue';
import VSelect from '@/components/Inputs/VSelect.vue';
import BoxContainer from '@/components/Elements/BoxContainer.vue';
import { z_index_room_booking_slideout } from '@/variables/z-indexes';

type Props = {
  initialBooking: RoomBookingResource | null;
  room: {
    id: number;
    name: string;
    title: string;
  };
  start: string;
  end: string;
  isRecurring?: boolean;
  allRooms?: {
    id: number;
    name: string;
  }[];
  allVenues?: {
    id: number;
    name: string;
    rooms: {
      id: number;
      name: string;
    }[];
  }[];
};

const props = withDefaults(defineProps<Props>(), { isRecurring: false, initialBooking: null, allRooms: () => [] });

const emit = defineEmits<{
  (event: 'closed'): void;
  (
    event: 'bookRoom',
    arg: {
      booking: {
        room_id: number;
        start: string;
        end: string;
        title: string | null;
        confirmed: boolean;
        is_global: boolean;
      };
      close: () => void;
    }
  ): void;
  (
    event: 'patchBooking',
    arg: {
      booking: {
        start: string;
        end: string;
        title: string | null;
        confirmed: boolean;
      };
      close: () => void;
    }
  ): void;
  (event: 'removeBooking', arg: { booking: RoomBookingResource; close: () => void }): void;
}>();

const roomBookingSchema = z.object({
  start: z.string(),
  end: z.string(),
  title: z.string().nullable(),
  confirmed: z.boolean(),
});

type NewRoomBookingResource = z.infer<typeof roomBookingSchema>;

const roomBookingForm = ref<NewRoomBookingResource>({
  start: getKey(props.initialBooking, 'start', props.start),
  end: getKey(props.initialBooking, 'end', props.end),
  title: getKey(props.initialBooking, 'title', ''),
  confirmed: getKey(props.initialBooking, 'confirmed', true),
  is_global: getKey(props.initialBooking, 'is_global', false),
});
const ignoreOtherUses = ref(false);
const ignoreRestrictions = ref(false);
const otherUses = ref<OtherUse[]>([]);
const restrictions = ref<Restriction[]>([]);
const loadingRestrictions = ref(false);
const checkAvailability = async () => {
  loadingRestrictions.value = true;
  const { data } = await getRoomBooking(props.room.id, roomBookingForm.value.start, roomBookingForm.value.end);
  otherUses.value = getKey(data, 'other_uses', []).filter((b) => {
    if (props.initialBooking) {
      return props.initialBooking.id !== b.id;
    }
    return true;
  });
  restrictions.value = getKey(data, 'restrictions', []);
  if (otherUses.value.length === 0 && otherUses.value.length === 0) {
    ignoreOtherUses.value = true;
    ignoreRestrictions.value = true;
    loadingRestrictions.value = false;
  } else {
    ignoreOtherUses.value = false;
    ignoreRestrictions.value = false;
    loadingRestrictions.value = false;
  }
};
const initialCheck = async () => {
  await checkAvailability();
  await nextTick();
  ignoreOtherUses.value = true;
  ignoreRestrictions.value = true;
};
initialCheck();
const debouncedFn = useDebounceFn(() => {
  checkAvailability();
}, 1000);
const selectedRoomId = ref(props.room.id);

watch([() => roomBookingForm.value.start, () => roomBookingForm.value.end], () => {
  loadingRestrictions.value = true;
  debouncedFn();
});
watch(selectedRoomId, () => {
  loadingRestrictions.value = true;
  debouncedFn();
});

const ignoreAll = () => {
  ignoreOtherUses.value = true;
  ignoreRestrictions.value = true;
};

const roomName = computed(() => {
  if (!props.allRooms?.length) return props.room.name;

  const selectedRoom = props.allRooms.find((room) => room.id === props.room.id);

  if (!selectedRoom) return props.room.name;

  return selectedRoom.name;
});

const onCreated = (close: () => void) => {
  emit('bookRoom', {
    booking: {
      room_id: selectedRoomId.value,
      start: roomBookingForm.value.start,
      end: roomBookingForm.value.end,
      title: roomBookingForm.value.title,
      confirmed: roomBookingForm.value.confirmed,
      is_global: roomBookingForm.value.is_global,
      room_name: roomName.value,
    },
    close: close,
  });
};

const onUpdate = (close: () => void) => {
  if (!props.initialBooking) return;

  emit('patchBooking', {
    booking: {
      start: roomBookingForm.value.start,
      end: roomBookingForm.value.end,
      title: roomBookingForm.value.title,
      confirmed: roomBookingForm.value.confirmed,
      is_global: roomBookingForm.value.is_global,
    },
    close: close,
  });
};

const onDelete = (close: () => void) => {
  if (!props.initialBooking) return;
  emit('removeBooking', { booking: props.initialBooking, close: close });
};

const isBeforeStart = computed(() => {
  return new Date(roomBookingForm.value.start) < new Date(props.start); // Or use Date comparison if using Date objects
});

const isAfterEnd = computed(() => {
  return new Date(roomBookingForm.value.end) > new Date(props.end); // Or use Date comparison if using Date objects
});

const showOutsideTimeNote = computed(() => {
  return isBeforeStart.value || isAfterEnd.value;
});
</script>

<template>
  <CrudSlideout
    :title="initialBooking?.id ? 'Edit Room Booking  ' : 'Book Room'"
    create-button-text="Book room"
    :update="initialBooking && initialBooking.id !== null"
    :disabled="
      ((!ignoreRestrictions || !ignoreOtherUses) && otherUses.length + restrictions.length > 0) || loadingRestrictions
    "
    small
    :loading="loadingRestrictions"
    class="bg-content-main"
    :with-first-input-focus="false"
    :base-z-index="z_index_room_booking_slideout"
    @create="onCreated"
    @update="onUpdate"
    @delete="onDelete"
    @closed="$emit('closed')">
    <div class="space-y-edge p-edge">
      <BoxContainer>
        <div class="form-layout">
          <div class="col-span-2">
            <VSelect
              v-if="allVenues.length === 0 && allRooms.length > 0"
              v-model="selectedRoomId"
              label="Room"
              can-search
              :can-edit="!initialBooking || !initialBooking.id"
              :options="allRooms" />
            <VSelect
              v-if="allVenues.length > 0 && allRooms.length === 0"
              v-model="selectedRoomId"
              label="Room"
              can-search
              :can-edit="!initialBooking || !initialBooking.id"
              groups
              :options="allVenues" />
          </div>

          <div class="col-span-2">
            <StartEndPicker
              v-model:start="roomBookingForm.start"
              v-model:end="roomBookingForm.end"
              required
              :allow-no-duration="false"
              vertical
              with-duration
              with-time />
          </div>

          <div class="col-span-2">
            <TextInput
              v-model="roomBookingForm.title"
              label="Room title" />
          </div>

          <div class="col-span-2 grid grid-cols-[auto_200px] gap-[64px]">
            <div class="flex flex-col gap-edge">
              <SettingCheck
                v-model="roomBookingForm.confirmed"
                label="Confirm booking" />

              <SettingCheck
                v-if="isRecurring && !initialBooking?.id"
                v-model="roomBookingForm.is_global"
                label="Recurring booking" />
            </div>
          </div>
          <div
            v-if="otherUses.length + restrictions.length > 0"
            class="col-start-2">
            <VButton
              v-if="!ignoreOtherUses || !ignoreRestrictions"
              class="float-right"
              size="sm"
              type="primary"
              emphasized
              @click="ignoreAll">
              Ignore Conflicts
            </VButton>
          </div>
        </div>
      </BoxContainer>
      <BoxContainer
        title="Notes"
        header-size="h3">
        <div
          v-if="loadingRestrictions"
          class="min-h-[21px]">
          <IconWithLoading loading />
        </div>
        <div v-else>
          <span
            v-if="otherUses.length + restrictions.length === 0"
            class="text-soft">
            No overlapping bookings or restrictions in the same time period
          </span>
          <br />
          <div
            v-if="showOutsideTimeNote"
            class="mt-edge-1/2 flex gap-edge-1/4 text-soft">
            <IconWithLoading
              class="text-warning"
              icon="fa-exclamation-circle"></IconWithLoading>
            The selected time is outside the Event Production Period
          </div>
        </div>
      </BoxContainer>

      <BoxContainer
        :title="`Conflicts ${otherUses.length > 0 ? `(${otherUses.length})` : ''}`"
        :content-padding="false"
        :overflow-scroll="true"
        header-size="h3">
        <div v-if="otherUses.length">
          <VTable
            :edge-to-edge="true"
            :bordered-table="true">
            <template #head>
              <VTableRow head>
                <VTableCell> Event</VTableCell>
                <VTableCell> When</VTableCell>
                <VTableCell style="width: 50px"> Confirmed</VTableCell>
              </VTableRow>
            </template>
            <VTableRow
              v-for="use in otherUses"
              :key="use.id">
              <VTableCell main-cell>
                {{ use.event.name }}
              </VTableCell>
              <VTableCell :title="formatStampAsDateTime(use.start)">
                {{
                  formatStampAsDate(
                    use.start,
                    timeStampsAreSame(use.start, roomBookingForm.start, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                  )
                }}
                -
                {{
                  formatStampAsDate(
                    use.end,
                    timeStampsAreSame(use.start, use.end, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                  )
                }}
                <!--                  {{ use.end }}-->
              </VTableCell>
              <VTableCell>
                <CheckBox
                  :model-value="use.confirmed"
                  :can-edit="false" />
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
        <div
          v-else-if="loadingRestrictions"
          class="min-h-[21px] px-edge">
          <IconWithLoading loading />
        </div>
        <div
          v-else
          class="px-edge">
          <p class="text-soft">No conflicts found.</p>
        </div>
      </BoxContainer>

      <BoxContainer
        :title="`Restrictions ${restrictions.length > 0 ? `(${restrictions.length})` : ''}`"
        :content-padding="false"
        :overflow-scroll="true"
        header-size="h3">
        <div v-if="restrictions.length">
          <VTable
            edge-to-edge
            :bordered-table="true">
            <template #head>
              <VTableRow head>
                <VTableCell> Room</VTableCell>
                <VTableCell style="min-width: 150px"> Message</VTableCell>
                <VTableCell> Event</VTableCell>
                <VTableCell> Duration</VTableCell>
                <VTableCell style="width: 50px"> Confirmed</VTableCell>
              </VTableRow>
            </template>
            <VTableRow
              v-for="use in restrictions"
              :key="`${use.room_id}_${use.eventI}`">
              <VTableCell main-cell>
                {{ use.room_name }}
              </VTableCell>
              <VTableCell>
                {{ use.message }}
              </VTableCell>
              <VTableCell>
                {{ use.eventName }}
              </VTableCell>
              <VTableCell
                :title="
                  formatStampAsDate(use.start, 'YYYY/MM/DD [at] HH:mm') +
                  ' - ' +
                  formatStampAsDate(use.end, 'YYYY/MM/DD [at] HH:mm')
                ">
                {{
                  formatStampAsDate(
                    use.start,
                    timeStampsAreSame(use.start, use.end, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                  )
                }}
                -
                {{
                  formatStampAsDate(
                    use.end,
                    timeStampsAreSame(use.start, use.end, 'day') ? 'HH:mm' : 'YYYY/MM/DD [at] HH:mm'
                  )
                }}
              </VTableCell>
              <VTableCell>
                <CheckBox
                  :model-value="use.confirmed"
                  :can-edit="false" />
              </VTableCell>
            </VTableRow>
          </VTable>
        </div>
        <div
          v-else-if="loadingRestrictions"
          class="min-h-[21px] px-edge">
          <IconWithLoading loading />
        </div>
        <div
          v-else
          class="px-edge">
          <p class="text-soft">No restrictions found.</p>
        </div>
      </BoxContainer>
    </div>
  </CrudSlideout>
</template>
