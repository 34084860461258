import { acceptHMRUpdate, defineStore } from 'pinia';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

export type GroupRequestCountResource = {
  pending_invites_count: number;
  pending_event_requests_count: number;
  pending_event_edit_count: number;
  somethingPending: boolean;
  pendingCount: number;
};

export const useGroupRequestCountsStore = defineStore('group-request-counts', () => {
  const list = ref<Map<number, GroupRequestCountResource[]>>(new Map());
  const loading = ref(false);

  const listenForBroadcast = (groupId: number) => {
    Echo.channel(`presence-On.Group.${groupId}`)
      .listen('.eventRequest.created', (e) => {
        useToast().success('A new request has been submitted.');
        fetchRequestCounts(groupId);
      })
      .listen('.eventRequest.updated', (e) => {
        fetchRequestCounts(groupId);
      })
      .listen('.group.invited', (e) => {
        fetchRequestCounts(groupId);
      })
      .listen('.eventEditRequest', (e) => {
        useToast().success('A new edit request has been submitted.');
        fetchRequestCounts(groupId);
      })
      .listen('.eventRequest.deleted', (e) => {
        fetchRequestCounts(groupId);
      });
  };

  const fetchRequestCounts = async (groupId: number) => {
    loading.value = true;
    const { data } = await axios.get<GroupRequestCountResource[]>(`/api/groups/${groupId}/request-counts`);
    if (!list.value.has(groupId)) {
      listenForBroadcast(groupId);
    }
    list.value.set(groupId, {
      ...data,
      somethingPending:
        data.pending_invites_count > 0 || data.pending_event_requests_count > 0 || data.pending_event_edit_count > 0,
      pendingCount: data.pending_invites_count + data.pending_event_requests_count + data.pending_event_edit_count,
    });
    loading.value = false;
  };
  const addOrUpdateRequestsCount = async (groupId: number, groupRequestCount: GroupRequestCountResource) => {
    if (!list.value.has(groupId)) {
      fetchRequestCounts(groupId);
    } else {
      list.value.set(groupId, groupRequestCount);
    }
  };

  return {
    list,
    loading,
    fetchRequestCounts,
    addOrUpdateRequestsCount,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGroupRequestCountsStore, import.meta.hot));
}
