<script lang="ts" setup>
import { useToast } from 'vue-toastification';
import { usePage } from '@inertiajs/vue3';
import { formatStampAsHumanReadableDate, formatStampAsTime, getNow } from '@/util/timeFunctions';
import { useCertaintyModal } from '@/composables/modals/use-certainty-modal';
import VTableCell from '@/components/Tables/VTableCell.vue';
import DisplayEmail from '@/components/Display/DisplayEmail.vue';
import DisplayPhone from '@/components/Display/DisplayPhone.vue';
import DisplayBadge from '@/components/Display/DisplayBadge.vue';
import VTableRow from '@/components/Tables/VTableRow.vue';
import VTable from '@/components/Tables/VTable.vue';
import { GuestFieldResource, GuestListResource, GuestListSettingResource, GuestResource } from '@/types/festival';
import { exchangeValuesOfObject, getItemFromArrayBasedOnId, getKey } from '@/util/globals';
import VButton from '@/components/Inputs/VButton.vue';
import ShowFieldValue from '@/components/Fields/ShowFieldValue.vue';
import TextInput from '@/components/Inputs/TextInput.vue';
import { ref } from 'vue';
import { highlightStringBySearch } from '@/util/text-replace-helper';

type Props = {
  guestLists: GuestListResource[];
  guestListSetting: GuestListSettingResource;
  canEditGuests: boolean;
};

const props = defineProps<Props>();
const emits = defineEmits<{
  (event: 'addOrUpdateGuestList', arg: GuestListResource): void;
}>();

const searchTerm = ref('');

const collectGuest = async (guestList: GuestListResource, guest: GuestResource) => {
  if (guest.collected_at === null) {
    axios.post(`/api/guests/${guest.id}/collect`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Already approved.');
        return;
      }
      guest.collected_by_type = 'App\\User';
      guest.collected_by_id = usePage().props.auth.user.id;
      guest.collected_by = {
        id: usePage().props.auth.user.id,
        name: usePage().props.auth.user.name,
      };
      guest.collected_at = getNow();
      useToast().success('Collected');
      const localGuestList = { ...guestList, guests: exchangeValuesOfObject(guest, guestList.guests) };
      emits('addOrUpdateGuestList', localGuestList);
    });
  } else {
    const certain = await useCertaintyModal().assertCertain(
      'Un-collect guest',
      'Are you sure you want to mark this guest as uncollected?'
    );
    if (!certain) return;
    axios.post(`/api/guests/${guest.id}/un-collect`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Not approved yet.');
        return;
      }
      guest.collected_by_type = null;
      guest.collected_by_id = null;
      guest.collected_by = null;
      guest.collected_at = null;
      useToast().success('Un-collected');
      const localGuestList = { ...guestList, guests: exchangeValuesOfObject(guest, guestList.guests) };
      emits('addOrUpdateGuestList', localGuestList);
    });
  }
};

const approveGuest = async (guestList: GuestListResource, guest: GuestResource) => {
  if (!props.canEditGuests) return;
  if (guest.approved_at === null) {
    axios.patch(`/api/guests/${guest.id}/approve`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Already approved.');
        return;
      }
      guest.approved_by = usePage().props.auth.user.name;
      guest.approved_by_id = usePage().props.auth.user.id;
      guest.approved_at = getNow();
      useToast().success('Approved');
      const localGuestList = { ...guestList, guests: exchangeValuesOfObject(guest, guestList.guests) };
      emits('addOrUpdateGuestList', localGuestList);
    });
  } else {
    const certain = await useCertaintyModal().assertCertain(
      'Un Approve Guest',
      'Are you sure you want to un approve this guest?'
    );
    if (!certain) return;
    axios.patch(`/api/guests/${guest.id}/un-approve`).then((response) => {
      if (response.status === 304) {
        useToast().warning('Not approved yet.');
        return;
      }
      guest.approved_by = null;
      guest.approved_by_id = null;
      guest.approved_at = null;
      useToast().success('Unapproved');
      const localGuestList = { ...guestList, guests: exchangeValuesOfObject(guest, guestList.guests) };
      emits('addOrUpdateGuestList', localGuestList);
    });
  }
};

const getFieldValueForGuest = (guest: GuestResource, field: GuestFieldResource) => {
  return getItemFromArrayBasedOnId(
    field.id,
    getKey(guest, 'guest_guest_field_pivots', []),
    { value: '' },
    'guest_field_id'
  ).value;
};

const filteredGuests = (guestList: GuestListResource) => {
  if (!searchTerm.value || searchTerm.value.length === 0) {
    return guestList.guests;
  }
  return guestList.guests.filter((g: GuestResource) => {
    const name = g.first_name + ' ' + (g.last_name ? g.last_name : '');
    let listableName = getKey(guestList.listable, 'name', '');
    if (!listableName) {
      listableName = '';
    }
    let title = guestList.title;
    if (!title) {
      title = '';
    }

    let email = g.email;
    if (!email) {
      email = '';
    }
    let phone = g.country_code + ' ' + g.phone;
    if (!phone) {
      phone = '';
    }

    const fields = g.guest_guest_field_pivots.filter((p) => {
      if (!p.value) return false;
      return p.value.toLowerCase().includes(searchTerm.value.toLowerCase());
    });

    return (
      name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      listableName.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      title.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      email.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      phone.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
      fields.length > 0
    );
  });
};

const getGuestCollectedTitle = (guest: GuestResource) => {
  if (!guest.collected_at) return 'Not Collected.';
  return (
    'Collected at ' +
    formatStampAsTime(guest.collected_at) +
    ' on ' +
    formatStampAsHumanReadableDate(guest.collected_at) +
    (guest.collected_by ? ' by ' + guest.collected_by.name : '') +
    '.'
  );
};

const getGuestApprovedTitle = (guest: GuestResource) => {
  if (!guest.approved_at) return 'Not Approved.';
  return (
    'Approved at ' +
    formatStampAsTime(guest.approved_at) +
    ' on ' +
    formatStampAsHumanReadableDate(guest.approved_at) +
    (guest.approved_by ? ' by ' + guest.approved_by : '') +
    '.'
  );
};

const showApproveColumn = () => {
  const unapprovedGuests = props.guestLists.flatMap((gList) => gList.guests.filter((g) => g.approved_at === null));
  if (unapprovedGuests.length > 0) return true;
  return !props.guestListSetting.auto_approve;
};
</script>

<template>
  <div>
    <TextInput
      v-model="searchTerm"
      placeholder="Search"
      :is-hidden="true"
      :square="true"
      class="w-[200px] border-none"
      left-icon="fa-search fa-regular !text" />
    <div class="overflow-auto">
      <VTable
        snap-rows
        sticky-header
        sticky-first-column
        sticky-last-column
        edge-to-edge>
        <template #head>
          <VTableRow head>
            <VTableCell> First Name</VTableCell>
            <VTableCell> Last Name</VTableCell>
            <VTableCell>Guest List</VTableCell>
            <VTableCell
              v-if="guestListSetting.use_email || guestListSetting.use_phone"
              style="min-width: 85px">
              Contact
            </VTableCell>
            <VTableCell
              v-for="field in getKey(guestListSetting, 'guest_fields', [])"
              style="min-width: 150px">
              {{ field.title }}
            </VTableCell>
            <VTableCell
              v-if="showApproveColumn()"
              style="width: 150px">
              Status
            </VTableCell>
            <VTableCell style="width: 150px"> Collected</VTableCell>
          </VTableRow>
        </template>
        <template v-for="guestList in guestLists">
          <VTableRow
            v-for="guest in filteredGuests(guestList)"
            :main-row="!guest.collected_at">
            <VTableCell style="min-width: 100px">
              <span v-html="highlightStringBySearch(guest.first_name, searchTerm)" />
            </VTableCell>
            <VTableCell style="min-width: 100px">
              <span v-html="highlightStringBySearch(guest.last_name, searchTerm)" />
            </VTableCell>
            <VTableCell style="min-width: 100px">
              <span
                v-html="
                  highlightStringBySearch(
                    getKey(guestList.listable, 'name', null) + getKey(guestList.listable, 'name', null) &&
                      guestList.title
                      ? ' - '
                      : '' + (guestList.title ? guestList.title : ''),
                    searchTerm
                  )
                "></span>
            </VTableCell>
            <VTableCell v-if="guestListSetting.use_email || guestListSetting.use_phone">
              <div class="flex gap-edge-1/2">
                <DisplayEmail
                  v-if="guest.email && guestListSetting.use_email"
                  :email="guest.email"
                  icon-only
                  :highlight-text="searchTerm" />
                <DisplayPhone
                  v-if="guestListSetting.use_phone && guest.phone"
                  :country-code="guest.country_code"
                  icon-only
                  :highlight-text="searchTerm"
                  :phone-number="guest.phone" />
              </div>
            </VTableCell>

            <VTableCell
              v-for="field in getKey(guestListSetting, 'guest_fields', [])"
              has-input>
              <ShowFieldValue
                :highlight-text="searchTerm"
                :value="getFieldValueForGuest(guest, field)"
                :field="field"></ShowFieldValue>
            </VTableCell>

            <VTableCell v-if="showApproveColumn()">
              <DisplayBadge
                v-if="guest.approved_at"
                size="small"
                classes="w-[80px]"
                color="green"
                :title="getGuestApprovedTitle(guest)"
                text="Approved" />
              <VButton
                v-else-if="canEditGuests"
                :tool-tip-text="'Not Approved.Click to approve'"
                title="Pending"
                hover-title="Approve"
                size="xs"
                class="group w-[80px]"
                icon="fa-exclamation-circle fa-regular"
                hover-icon="fa-check fa-regular"
                type="pending"
                @click.stop="approveGuest(guestList, guest)" />

              <div
                v-else
                :title="'Not Approved.'"
                class="btn btn-outline-text btn-xs w-[80px]">
                <i class="fa fa-fw fa-exclamation-circle text-pending" />
                Pending
              </div>
            </VTableCell>
            <VTableCell>
              <DisplayBadge
                v-if="guest.collected_at"
                size="small"
                :title="getGuestCollectedTitle(guest)"
                classes="w-[80px]"
                color="success"
                text="Collected"
                @click="collectGuest(guestList, guest)" />
              <VButton
                v-if="!guest.collected_at"
                icon="fa-check fa-regular"
                size="xs"
                classes="w-[80px]"
                :disabled="!guest.approved_at"
                title="Collect"
                type="primary"
                :stop-click="true"
                @click="collectGuest(guestList, guest)">
              </VButton>
            </VTableCell>
          </VTableRow>
        </template>
      </VTable>
    </div>
  </div>
</template>
